import MetricsCheckboxList from '@/components/metrics/MetricsCheckboxList';
import { TextField, Typography } from '@mui/material';
import { ChangeEvent, FunctionComponent, useState, useEffect, useContext } from 'react';
import EntityTypeSelect, { EntityType } from '../../forms/EntityTypeSelect';
import GroupByTimeSelect, { GroupByTimeSelectOption } from '../../forms/GroupByTimeSelect';
import { ComboGraphWidgetConfiguration } from './ComboGraphWidgetConfiguration';
import { CommonMetricField, CommonWithSellerMetricField, MetricField, SellerMetricField } from '@/components/metrics/types/MetricField';
import { Environment } from '@/config/Environment';
import { cloneDeep } from 'lodash-es';
import { DashboardWidgetContext } from '../../dashboard-widget/DashboardWidgetContextProvider';
import ComboGraphWidgetFilters from './ComboGraphWidgetFilters';
import ProfileSelect from '../../forms/ProfileSelect';
import { useDashboardContextValue } from '@/modules/dashboards/contexts/DashboardContextProvider';

interface ComboGraphWidgetConfigurationFormProps {
  configuration: ComboGraphWidgetConfiguration;
  id: string;
}

const ComboGraphWidgetConfigurationForm: FunctionComponent<ComboGraphWidgetConfigurationFormProps> = ({ configuration }) => {
  const { setModifiedConfiguration } = useContext(DashboardWidgetContext);
  const dashboardProfiles = useDashboardContextValue((context) => context.dashboard?.teamProfiles);

  const [localConfiguration, setLocalConfiguration] = useState<ComboGraphWidgetConfiguration>(cloneDeep(configuration));

  useEffect(() => {
    localConfiguration.isConfigured = true;
    setModifiedConfiguration(cloneDeep(localConfiguration));
  }, [localConfiguration, setModifiedConfiguration]);

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.title = e.currentTarget.value;
      return newConfig;
    });
  };

  const handleGroupByTimeChange = (groupByTime: GroupByTimeSelectOption) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.groupByTime = groupByTime.value;
      return newConfig;
    });
  };

  const handleSelectedMetricsChange = (selectedMetrics: MetricField[]) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.selectedMetrics = selectedMetrics as CommonWithSellerMetricField[];
      return newConfig;
    });
  };

  const handleEntityTypeChange = (entityType: EntityType | '') => {
    if (entityType === '') return;
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.entityType = entityType;
      return newConfig;
    });
  };

  const availableMetricKeys = [
    ...Object.values(CommonMetricField),
    ...(Environment.isDev() ? Object.values(SellerMetricField) : []),
  ] as MetricField[];

  const onSelectedProfilesChanged = (selectedProfileIds: string[]) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.profilesIds = selectedProfileIds;
      return newConfig;
    });
  };

  // NB! combo graph widget does not support profile type
  // It does not make any sense to group by at profile level

  return (
    <>
      <TextField label="Name" value={localConfiguration.title} onChange={handleTitleChange} />
      {dashboardProfiles && (
        <ProfileSelect
          profiles={dashboardProfiles}
          selectedProfileIds={localConfiguration.profilesIds}
          setSelectedProfileIds={onSelectedProfilesChanged}
        />
      )}
      <EntityTypeSelect
        value={localConfiguration.entityType}
        onChange={handleEntityTypeChange}
        helperText={'Select an Entity Type to filter on.'}
        disabledEntityTypes={[EntityType.PROFILE, EntityType.SEARCH_TERM, EntityType.PRODUCT, EntityType.PLACEMENT]}
      />
      <div>
        <div className="text-sm">Time Grouping</div>
        <Typography variant="caption">How the data will be grouped on the x-axis</Typography>
        <div className="mt-1">
          <GroupByTimeSelect value={localConfiguration.groupByTime} onChange={handleGroupByTimeChange} />
        </div>
      </div>
      <div>
        <div className="text-sm">Metrics</div>
        <Typography variant="caption">Select the metrics to display</Typography>

        <MetricsCheckboxList
          availableMetrics={availableMetricKeys}
          selectedMetrics={localConfiguration.selectedMetrics}
          onChange={handleSelectedMetricsChange}
          maxSelectedItems={4}
        />
      </div>
      <ComboGraphWidgetFilters configuration={localConfiguration} setConfiguration={setLocalConfiguration} />
    </>
  );
};

export default ComboGraphWidgetConfigurationForm;
