import { useDataGroups } from '@/hooks/useDataGroups';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import { Autocomplete, TextField } from '@mui/material';
import { FunctionComponent, SyntheticEvent } from 'react';

export enum GroupByField {
  AD_TYPE = 'AD_TYPE',
}

export enum GroupByCategory {
  PROPERTY = 'PROPERTY',
  DATA_GROUP = 'DATA_GROUP',
}

interface GroupBySelectProps {
  value: number | GroupByField;
  onChange: (value: TableWidgetGroupByOptionSelectOption) => void;
  helperText?: string;
  dataGroupType?: DataGroupType;
}

export interface TableWidgetGroupByOptionSelectOption {
  label: string;
  category: GroupByCategory;
  value: number | GroupByField;
}

const GroupBySelect: FunctionComponent<GroupBySelectProps> = ({ value, onChange, dataGroupType }) => {
  const { dataGroups: campaignDataGroups } = useDataGroups([DataGroupType.CAMPAIGN]);
  const { dataGroups: targetDataGroups } = useDataGroups([DataGroupType.TARGET]);

  const options: TableWidgetGroupByOptionSelectOption[] = [
    { label: 'Ad Type', category: GroupByCategory.PROPERTY, value: GroupByField.AD_TYPE },
  ];

  // Based on the dataGroupType, add the dataGroups to the options
  if (dataGroupType === DataGroupType.CAMPAIGN) {
    campaignDataGroups?.forEach((dataGroup) => {
      options.push({
        label: dataGroup.name,
        category: GroupByCategory.DATA_GROUP,
        value: dataGroup.id,
      });
    });
  } else if (dataGroupType === DataGroupType.TARGET) {
    targetDataGroups?.forEach((dataGroup) => {
      options.push({
        label: dataGroup.name,
        category: GroupByCategory.DATA_GROUP,
        value: dataGroup.id,
      });
    });
  }

  if (options.find((option) => option.value === value) === undefined) {
    options.push({ label: 'Loading...', category: GroupByCategory.PROPERTY, value: value });
  }

  function onSelectionChanged(event: SyntheticEvent<Element, Event>, value: TableWidgetGroupByOptionSelectOption) {
    onChange(value);
  }

  return (
    <Autocomplete
      id="tags-standard"
      disableClearable
      value={options.find((option) => option.value === value)}
      options={options}
      groupBy={(option) => option.category}
      renderGroup={(params) => (
        <div key={params.key}>
          <div className="px-4 py-0.5 bg-gray-100 font-bold text-gray-600 lowercase first-letter:uppercase">
            {params.group === GroupByCategory.PROPERTY ? 'Property' : 'Data Group'}
          </div>
          <div>{params.children}</div>
        </div>
      )}
      getOptionLabel={(option) => option.label}
      getOptionKey={(option) => `${option.value}`}
      isOptionEqualToValue={(option, value) => {
        return option.value === value.value;
      }}
      onChange={onSelectionChanged}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Group By"
          helperText="Select a Field or Tag to group Campaigns by"
          slotProps={{
            inputLabel: { shrink: true },
          }}
        />
      )}
    />
  );
};

export default GroupBySelect;
