import { DATE_FORMAT } from '@/components/filter-builder/FiltersConfig';
import {
  ComparisonDateFilterModel,
  DateFilterModel,
  FilterCondition,
  LogicalOperatorType,
  OperatorType,
} from '@/components/filter-builder/models/AlFilterModel';
import {
  comparisonCalenderDefaultShortcutsItems,
  getCalenderShortcutsItems,
} from '@/modules/application/components/date-range-picker/DateRangePickerConfig';
import { PaywallModal } from '@/modules/plans/components/PaywallModal';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useUserContext } from '@/modules/users';
import { Routes } from '@/router/router-paths';
import { Button, Paper } from '@mui/material';
import { DateRange, LocalizationProvider, PickersShortcutsItem } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import dayjs, { Dayjs } from 'dayjs';
import { floor, isEmpty, isNil } from 'lodash-es';
import * as React from 'react';
import { useEffect, useState } from 'react';

import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

interface DateRangePickerProps {
  title: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (filters: DateFilterModel[]) => void;
  defaultDateRange: FilterCondition[] | undefined;
  defaultComparisonDateRange: FilterCondition[] | undefined;
  disablePaywall?: boolean;
  hideComparison?: boolean;
  minDateString?: string;
}

export default function DateRangePicker({
  title,
  setIsOpen,
  onSubmit,
  defaultDateRange,
  defaultComparisonDateRange,
  disablePaywall,
  hideComparison = false,
  minDateString,
}: DateRangePickerProps) {
  const { activeTeam } = useActiveTeamContext();
  // if there is an existing date range value in filters then it is used as default
  const { isAdminModeActive } = useUserContext();

  const today = dayjs().utc();
  const [mainCalendarDateRange, setMainCalendarDateRange] = useState<DateRange<Dayjs>>(
    defaultDateRange
      ? [dayjs.utc(defaultDateRange[0].values[0]), dayjs.utc(defaultDateRange[1].values[0])]
      : [today.subtract(6, 'day'), today],
  );

  // if there is an existing date range value in filters then it is used as default
  const [comparisonCalendarDateRange, setComparisonCalendarDateRange] = useState<DateRange<Dayjs>>(
    defaultComparisonDateRange
      ? [dayjs.utc(defaultComparisonDateRange[0].values[0]), dayjs.utc(defaultComparisonDateRange[1].values[0], DATE_FORMAT)]
      : [today.subtract(13, 'day'), today.subtract(7, 'day')],
  );

  const handleMainCalendarDateChange = (newValue: DateRange<Dayjs>) => {
    setMainCalendarDateRange(newValue);

    const startDate = newValue[0];
    const endDate = newValue[1];

    // Check if both start and end dates are defined before updating the comparison date
    if (startDate && endDate) {
      const periodLengthInDays = endDate.diff(startDate, 'day');
      const newComparisonStart = startDate.subtract(periodLengthInDays + 1, 'day');
      const newComparisonEnd = endDate.subtract(periodLengthInDays + 1, 'day');
      setComparisonCalendarDateRange([newComparisonStart, newComparisonEnd]);
    } else {
      // If either date is undefined, set the comparison value to [null, null]
      setComparisonCalendarDateRange([null, null]);
    }
  };

  const handleComparisonCalendarDateChange = (newValue: DateRange<Dayjs>) => {
    const startDate = newValue[0];
    const endDate = newValue[1];
    if (startDate && endDate) {
      setComparisonCalendarDateRange([startDate, endDate]);
    }
  };

  const [comparisonCalenderShortcutsItems, setComparisonCalenderShortcutsItems] = useState<PickersShortcutsItem<DateRange<Dayjs>>[]>(
    comparisonCalenderDefaultShortcutsItems,
  );

  useEffect(() => {
    setComparisonCalenderShortcutsItems([
      {
        label: 'Previous Period',
        getValue: () => {
          const mainCalendarStartDate = mainCalendarDateRange[0];
          const mainCalendarEndDate = mainCalendarDateRange[1];
          if (!mainCalendarStartDate || !mainCalendarEndDate) return [null, null];

          const periodLengthInDays = mainCalendarEndDate.diff(mainCalendarStartDate, 'day');
          const start = mainCalendarStartDate.subtract(periodLengthInDays + 1, 'day');
          const end = mainCalendarEndDate.subtract(periodLengthInDays + 1, 'day');
          return [start, end];
        },
      },
      {
        label: 'Previous Period (Day of Week)',
        getValue: () => {
          const mainCalendarStartDate = mainCalendarDateRange[0];
          const mainCalendarEndDate = mainCalendarDateRange[1];
          if (!mainCalendarStartDate || !mainCalendarEndDate) return [null, null];
          const periodLengthInDays = mainCalendarEndDate.diff(mainCalendarStartDate, 'day');
          const start = mainCalendarStartDate.subtract(floor(periodLengthInDays / 7) * 7 + 7, 'day');
          const end = mainCalendarEndDate.subtract(floor(periodLengthInDays / 7) * 7 + 7, 'day');
          return [start, end];
        },
      },
      { label: 'Custom', getValue: () => [null, null] },
    ]);
  }, [mainCalendarDateRange]);

  function onApplyDateRangeFilterClicked() {
    const startDate = mainCalendarDateRange[0];
    const endDate = mainCalendarDateRange[1];

    const newFilterValues: DateFilterModel[] = [];

    if (startDate && endDate) {
      const periodLengthInDays = endDate.diff(startDate, 'day');

      if (
        !isAdminModeActive &&
        activeTeam &&
        periodLengthInDays > activeTeam.subscriptionPlan.dateRangeLengthLimitInDays &&
        !disablePaywall
      ) {
        setIsPaywallModalOpen(true);
        return;
      }

      const newFilter = new DateFilterModel({
        logicalOperator: LogicalOperatorType.AND,
        conditions: [
          {
            values: [startDate.format(DATE_FORMAT)],
            operator: OperatorType.GREATER_THAN_OR_EQUAL,
          },
          {
            values: [endDate.format(DATE_FORMAT)],
            operator: OperatorType.LESS_THAN_OR_EQUAL,
          },
        ],
      });
      newFilterValues.push(newFilter);
    }

    if (comparisonCalendarDateRange[0] && comparisonCalendarDateRange[1]) {
      const newFilter = new ComparisonDateFilterModel({
        logicalOperator: LogicalOperatorType.AND,
        conditions: [
          {
            values: [comparisonCalendarDateRange[0].format(DATE_FORMAT)],
            operator: OperatorType.GREATER_THAN_OR_EQUAL,
          },
          {
            values: [comparisonCalendarDateRange[1].format(DATE_FORMAT)],
            operator: OperatorType.LESS_THAN_OR_EQUAL,
          },
        ],
      });
      newFilterValues.push(newFilter);
    }

    if (newFilterValues.length > 0) onSubmit(newFilterValues);
    setIsOpen(false);
  }

  // Paywall modal
  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState(false);
  const onClosePaywallModal = () => {
    setIsPaywallModalOpen(false);
  };

  const minDate = !isNil(minDateString) && !isEmpty(minDateString) ? dayjs.utc(minDateString) : undefined;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <PaywallModal isOpen={isPaywallModalOpen} onClose={onClosePaywallModal} returnURLPath={Routes.BILLING}>
        {`Free tier limit of ${activeTeam?.subscriptionPlan.dateRangeLengthLimitInDays} days reached. Upgrade to access unlimited data retention.`}
      </PaywallModal>

      <div className="flex h-full flex-col justify-between px-2 pt-4">
        <div>
          <div className="my-0 ml-4 text-lg font-bold">{title}</div>
          <StaticDateRangePicker
            className="bg-transparent"
            slots={{
              toolbar: () => <div> </div>,
            }}
            slotProps={{
              shortcuts: {
                items: getCalenderShortcutsItems(minDate),
                sx: {
                  p: 2,
                  width: 250,
                },
              },
              actionBar: { actions: [] },
            }}
            disableFuture
            minDate={minDate}
            calendars={2}
            value={mainCalendarDateRange}
            onChange={handleMainCalendarDateChange}
          />
        </div>
        {!hideComparison && (
          <div>
            <div className="my-0 ml-4 text-lg font-bold">Comparison Period</div>

            <StaticDateRangePicker
              className="bg-transparent"
              slots={{
                toolbar: () => <div> </div>,
              }}
              slotProps={{
                shortcuts: {
                  items: comparisonCalenderShortcutsItems,
                  sx: {
                    p: 2,
                    width: 250,
                  },
                  dense: true,
                  disablePadding: true,
                },
                actionBar: { actions: [] },
              }}
              disableFuture
              calendars={2}
              minDate={minDate}
              value={comparisonCalendarDateRange}
              onChange={handleComparisonCalendarDateChange}
            />
          </div>
        )}
      </div>
      <Paper
        square
        className="sticky bottom-0 left-0 right-0 flex flex-row justify-end  gap-2 border-t px-3  py-2 dark:border-gray-700 dark:bg-gray-700"
      >
        <Button variant="text" onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <Button onClick={onApplyDateRangeFilterClicked}>Apply Date Ranges</Button>
      </Paper>
    </LocalizationProvider>
  );
}
