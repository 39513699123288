import MetricsRadioList from '@/components/metrics/MetricsRadioList';
import { DataGroupType } from '@/modules/data-groups/models/data-groups-contracts';
import { TextField, Typography } from '@mui/material';
import { ChangeEvent, FunctionComponent, useContext, useEffect, useState } from 'react';
import EntityTypeSelect, { EntityType } from '../../forms/EntityTypeSelect';
import GroupBySelect, { TableWidgetGroupByOptionSelectOption } from '../../forms/GroupBySelect';
import { PieGraphWidgetConfiguration } from './PieGraphWidgetConfiguration';
import { CommonMetricField, MetricField, SellerMetricField } from '@/components/metrics/types/MetricField';
import { Environment } from '@/config/Environment';
import { cloneDeep } from 'lodash-es';
import { DashboardWidgetContext } from '../../dashboard-widget/DashboardWidgetContextProvider';
import PieGraphWidgetFilters from './PieGraphWidgetFilters';
import ProfileSelect from '../../forms/ProfileSelect';
import { useDashboardContextValue } from '@/modules/dashboards/contexts/DashboardContextProvider';

interface PieGraphWidgetConfigurationFormProps {
  configuration: PieGraphWidgetConfiguration;
  id: string;
}

const PieGraphWidgetConfigurationForm: FunctionComponent<PieGraphWidgetConfigurationFormProps> = ({ configuration }) => {
  const { setModifiedConfiguration } = useContext(DashboardWidgetContext);
  const dashboardProfiles = useDashboardContextValue((context) => context.dashboard?.teamProfiles);

  const [localConfiguration, setLocalConfiguration] = useState<PieGraphWidgetConfiguration>(cloneDeep(configuration));

  // Update the modified configuration in the context whenever localConfiguration changes
  useEffect(() => {
    localConfiguration.isConfigured = true;
    setModifiedConfiguration(cloneDeep(localConfiguration));
  }, [localConfiguration, setModifiedConfiguration]);

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.title = e.currentTarget.value;
      return newConfig;
    });
  };

  const handleEntityTypeChange = (entityType: EntityType | '') => {
    if (entityType === '') return;
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.entityType = entityType;
      return newConfig;
    });
  };

  const handleCampaignGroupByChange = (groupByOption: TableWidgetGroupByOptionSelectOption) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.campaignGroupByValue = groupByOption.value;
      newConfig.campaignGroupByCategory = groupByOption.category;
      return newConfig;
    });
  };

  const handleTargetsGroupByChange = (groupByOption: TableWidgetGroupByOptionSelectOption) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.targetsGroupByValue = groupByOption.value;
      newConfig.targetsGroupByCategory = groupByOption.category;
      return newConfig;
    });
  };

  const handleSelectedMetricChange = (selectedMetric: MetricField) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.selectedMetric = selectedMetric;
      return newConfig;
    });
  };

  // TODO: move to context. SellerMetricField depends if profile is a seller or vendor
  const availableMetricKeys = [
    ...Object.values(CommonMetricField),
    ...(Environment.isDev() ? Object.values(SellerMetricField) : []),
  ] as MetricField[];

  const onSelectedProfilesChanged = (selectedProfileIds: string[]) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.profilesIds = selectedProfileIds;
      return newConfig;
    });
  };

  return (
    <>
      <TextField label="Name" value={localConfiguration.title} onChange={handleTitleChange} />

      {dashboardProfiles && (
        <ProfileSelect
          profiles={dashboardProfiles}
          selectedProfileIds={localConfiguration.profilesIds}
          setSelectedProfileIds={onSelectedProfilesChanged}
        />
      )}

      <EntityTypeSelect
        value={localConfiguration.entityType}
        onChange={handleEntityTypeChange}
        helperText={'Select an Entity Type to filter on.'}
        disabledEntityTypes={[EntityType.SEARCH_TERM, EntityType.PRODUCT, EntityType.PLACEMENT]}
      />

      {localConfiguration.entityType === EntityType.CAMPAIGN && (
        <GroupBySelect
          value={localConfiguration.campaignGroupByValue}
          onChange={handleCampaignGroupByChange}
          dataGroupType={DataGroupType.CAMPAIGN}
        />
      )}

      {localConfiguration.entityType === EntityType.TARGET && (
        <GroupBySelect
          value={localConfiguration.targetsGroupByValue}
          onChange={handleTargetsGroupByChange}
          dataGroupType={DataGroupType.TARGET}
        />
      )}
      <div>
        <div className="text-sm">Metric</div>
        <Typography variant="caption"> Select the metric to display</Typography>
        <MetricsRadioList
          value={localConfiguration.selectedMetric}
          onChange={handleSelectedMetricChange}
          availableMetrics={availableMetricKeys}
        />
      </div>

      <PieGraphWidgetFilters configuration={localConfiguration} setConfiguration={setLocalConfiguration} />
    </>
  );
};

export default PieGraphWidgetConfigurationForm;
