import { FunctionComponent, PropsWithChildren, useContext, useCallback } from 'react';
import { DashboardWidgetContext } from './DashboardWidgetContextProvider';
import LoadingBlock from '@/components/feedback/LoadingBlock';
import { useDashboardContextValue } from '../../contexts/DashboardContextProvider';
import { Settings } from '@mui/icons-material';

interface DashboardWidgetContentProps extends PropsWithChildren {
  onMouseChange: (isMouseOnWidget: boolean) => void;
}

const DashboardWidgetContent: FunctionComponent<DashboardWidgetContentProps> = ({ children, onMouseChange }) => {
  const { isLoading, setIsConfiguring, widgetId, isConfigured } = useContext(DashboardWidgetContext);
  const { setEditingWidgetId, editingWidgetId, isEditingDashboard } = useDashboardContextValue((context) => ({
    setEditingWidgetId: context.setEditingWidgetId,
    editingWidgetId: context.editingWidgetId,
    isEditingDashboard: context.isEditingDashboard,
  }));

  const handleMouseEnter = useCallback(() => {
    onMouseChange(true);
  }, [onMouseChange]);

  const handleMouseLeave = useCallback(() => {
    onMouseChange(false);
  }, [onMouseChange]);

  if (isLoading && isConfigured) {
    return <LoadingBlock />;
  }

  return (
    <div
      className="relative flex h-full w-full flex-col"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onLostPointerCapture={handleMouseLeave}
    >
      {!isConfigured ? (
        <div className="absolute top-0 left-0 z-50 w-full h-full rounded-md flex items-center justify-center">
          <div
            className="flex flex-col items-center justify-center space-y-1 cursor-pointer absolute z-50 hover:underline group  "
            onMouseDown={() => {
              console.log('clicked');
              setIsConfiguring(true);
              setEditingWidgetId(widgetId ?? '');
            }}
          >
            <Settings className="text-gray-400 w-8 h-8 group-hover:text-primary-500" />
            <span className="text-xs text-gray-700">Configure this widget</span>
          </div>
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export default DashboardWidgetContent;
