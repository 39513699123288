import MetricSelect from '@/components/form/MetricSelect';
import { METRICS } from '@/components/metrics/MetricsConfig';
import { CommonWithSellerMetricField } from '@/components/metrics/types/MetricField';
import { TextField } from '@mui/material';
import { FunctionComponent, useState, useEffect, useContext } from 'react';
import { MetricWidgetConfiguration } from './MetricWidgetConfiguration';
import { cloneDeep } from 'lodash-es';
import { DashboardWidgetContext } from '../../dashboard-widget/DashboardWidgetContextProvider';
import EntityTypeSelect, { EntityType } from '../../forms/EntityTypeSelect';
import MetricWidgetFilters from './MetricWidgetFilters';
import ProfileSelect from '../../forms/ProfileSelect';
import { useDashboardContextValue } from '@/modules/dashboards/contexts/DashboardContextProvider';

interface MetricWidgetConfigurationFormProps {
  configuration: MetricWidgetConfiguration;
  id: string;
}

const MetricWidgetConfigurationForm: FunctionComponent<MetricWidgetConfigurationFormProps> = ({ configuration }) => {
  const { setModifiedConfiguration } = useContext(DashboardWidgetContext);
  const dashboardProfiles = useDashboardContextValue((context) => context.dashboard?.teamProfiles);

  const [localConfiguration, setLocalConfiguration] = useState<MetricWidgetConfiguration>(cloneDeep(configuration));

  // Update the modified configuration in the context whenever localConfiguration changes
  useEffect(() => {
    localConfiguration.isConfigured = true;
    setModifiedConfiguration(cloneDeep(localConfiguration));
  }, [localConfiguration, setModifiedConfiguration]);

  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.title = e.currentTarget.value;
      return newConfig;
    });
  };

  const onMetricChange = (metricField: '' | CommonWithSellerMetricField) => {
    if (metricField !== '') {
      const configuredMetric = METRICS[metricField];
      setLocalConfiguration((prev) => {
        const newConfig = cloneDeep(prev);
        newConfig.metricField = metricField;
        // Optionally update the title to match the metric's title
        newConfig.title = configuredMetric?.title || prev.title;
        return newConfig;
      });
    }
  };

  const handleEntityTypeChange = (entityType: EntityType | '') => {
    if (entityType === '') return;
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.entityType = entityType;
      return newConfig;
    });
  };

  const onSelectedProfilesChanged = (selectedProfileIds: string[]) => {
    setLocalConfiguration((prev) => {
      const newConfig = cloneDeep(prev);
      newConfig.profilesIds = selectedProfileIds;
      return newConfig;
    });
  };

  return (
    <>
      <TextField label="Name" value={localConfiguration.title} onChange={onTitleChange} />

      <MetricSelect
        entityType={localConfiguration.entityType}
        value={localConfiguration.metricField}
        onChange={onMetricChange}
        helperText={'Select a Metric to display.'}
      />

      {dashboardProfiles && (
        <ProfileSelect
          profiles={dashboardProfiles}
          selectedProfileIds={localConfiguration.profilesIds}
          setSelectedProfileIds={onSelectedProfilesChanged}
        />
      )}

      <EntityTypeSelect
        value={localConfiguration.entityType}
        onChange={handleEntityTypeChange}
        helperText={'Select an Entity Type to filter on.'}
        disabledEntityTypes={[EntityType.SEARCH_TERM, EntityType.PRODUCT, EntityType.PLACEMENT]}
      />

      <MetricWidgetFilters configuration={localConfiguration} setConfiguration={setLocalConfiguration} />
    </>
  );
};

export default MetricWidgetConfigurationForm;
