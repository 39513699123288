import { filtersService } from '@/components/filter-builder/api/filters-service';
import { AlFilterModel, getDefaultPlacementsFilters, getUpdatedFiltersValue } from '@/components/filter-builder/models/AlFilterModel';
import { GridToggles } from '@/components/grid/types';
import useMetricsInContext from '@/hooks/useMetricsInContext';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { UserSettingKey } from '@/modules/users';
import {
  DEFAULT_GRID_TOGGLES,
  FiltersPropsWithChildren,
  MetricsGraphTablePageContext,
  defaultMetricsGraphTablePageContext,
} from '@/types/context-shared';
import { cloneDeep, isEqual } from 'lodash-es';
import { FunctionComponent, createContext, useContext, useEffect, useState } from 'react';

export const PLACEMENTS_CONTEXT_KEY = 'placements-context';
const METRICS_USER_SETTING_KEY_BY_CONTEXT = UserSettingKey.VISIBLE_METRICS_PLACEMENTS;

const PlacementsContext = createContext<MetricsGraphTablePageContext>({
  ...defaultMetricsGraphTablePageContext,
});

export const PlacementsProvider: FunctionComponent<FiltersPropsWithChildren> = ({ filters, setFilters, children }) => {
  const { activeProfileIdChange, activeProfile } = useActiveTeamContext();

  const { visibleMetrics, setVisibleMetrics } = useMetricsInContext(METRICS_USER_SETTING_KEY_BY_CONTEXT);

  const [gridToggles, setGridToggles] = useState<GridToggles>(DEFAULT_GRID_TOGGLES);
  const [isMounted, setIsMounted] = useState(false);

  const setFilterValue = (filter: AlFilterModel) => {
    const newFilters = getUpdatedFiltersValue(filters, filter);
    setFilterValues(newFilters);
  };

  const setFilterValues = (newFilters: AlFilterModel[]) => {
    if (!newFilters || newFilters.length === 0) return;

    const updatedFilters = newFilters.reduce((acc, newFilter) => {
      return getUpdatedFiltersValue(acc, newFilter);
    }, cloneDeep(filters));

    if (isEqual(filters, updatedFilters)) return;

    setFilters(updatedFilters);
  };

  //TODO: review
  useEffect(() => {
    if (!isMounted) return;
    if (!activeProfile) return;

    filtersService.saveProfileFilters(PLACEMENTS_CONTEXT_KEY, activeProfile.id, filters);
  }, [filters]);

  useEffect(() => {
    if (activeProfileIdChange && activeProfileIdChange.hasChanged && activeProfile?.id) {
      const profileFilters = filtersService.loadProfileFilters(PLACEMENTS_CONTEXT_KEY, activeProfile?.id, getDefaultPlacementsFilters());

      if (!isEqual(profileFilters, filters)) {
        setFilters(profileFilters);
      }
    }
  }, [activeProfileIdChange]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <PlacementsContext.Provider
      value={{
        filters,
        setFilters,
        setFilterValue,
        setFilterValues,
        visibleMetrics,
        setVisibleMetrics,
        gridToggles,
        setGridToggles,
      }}
    >
      {children}
    </PlacementsContext.Provider>
  );
};

export const usePlacementsContext = (): MetricsGraphTablePageContext => {
  const context = useContext(PlacementsContext);
  if (!context) {
    throw new Error('usePlacementsContext must be used within a PlacementsProvider');
  }
  return context;
};
